import { useEffect, useMemo, useState } from "react";
import useWebsocket from "../../../../../websocket/hooks/useWebsocket";
import { MaintenanceData } from "./Interfaces";

const useMaintenance = () => {
	const [data, setData] = useState<MaintenanceData>({
		page_state: {
			sort_by: {},
		},
		maintenance: {
			molds: [],
			machines: [],
			maintenance_types: [],
		},
	});

	const { loading, sendMessage, isVisible, setPageState } = useWebsocket('/inventory/maintenance/', setData);

	const pageState = data.page_state;

	// Log and potentially re-set state when visibility changes
	useEffect(() => {
		if (!isVisible) {
			setPageState(pageState);
		}
	}, [isVisible]);

	const maintenance = useMemo(() => data.maintenance, [data]);

	return {
		loading, sendMessage,
		maintenance
	};
};

export default useMaintenance;
