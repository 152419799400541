import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SemiCircleGauge from '../charts/SemiCircleGauge'; // Adjust the import path as needed
import { ProductivityDetail } from '../Interfaces';

interface ProductivityDetailDialogProps {
	type: string
	open: boolean;
	handleClose: () => void;
	productivityDetails: ProductivityDetail[];
}

const ProductivityDetailDialog: React.FC<ProductivityDetailDialogProps> = ({ type, open, handleClose, productivityDetails }) => {
	const navigate = useNavigate();
	const navigateTo = (url: string) => {
		navigate(url);
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
			<DialogTitle align='center'>{type} Productivties</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={2}>
					{productivityDetails.map((detail, index) => (
						<Grid item xs={12} sm={6} md={3} key={index}>
							<SemiCircleGauge
								height={230}
								value={detail.productivity}
								label={detail.name}
								tooltip={detail.tooltip}
								onClick={() => navigateTo(detail.url)}
							/>
						</Grid>
					))}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ProductivityDetailDialog;
