// React imports
import React, { FC, useState, useEffect } from 'react';
// MUI imports
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
// Custom imports
import Login from '../login/login';
import NavHeader from '../navheader/navheader';
import { AuthService } from '../../utils/auth';
import { api } from '../../utils/globals';
import Confirm from '../../utils/modals/Confirm';
import PopupAlert from '../../utils/globalComps/PopupAlert/PopupAlert';

const authService = new AuthService();

/**
 * Main comp
 * @constructor
 */
const Main: FC = () => {
  const [authed, setAuthed] = useState<boolean>(false);
  const [themeMode, setThemeMode] = useState<string>('light');
  const [loading, setLoading] = useState<boolean>(true);

  // can toggle between these
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      background: {default: '#f4f9ff'},
      primary: {main: '#178de0'}
    }
  });
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    }
  });

  // handles login/logout
  const handleLogin = () => setAuthed(true)
  const handleLogout = () => {
    setAuthed(false)
    authService.logout()
  };

  // Toggle theme change
  const handleThemeChange = (theme: string) => setThemeMode(theme);

  // Check if cached token, auto login if so
  useEffect(() => {

    let isActive = true;

    if (isActive)
      api.get('/auth/token/check')
        .then((resp: any) => {
          setLoading(false)
          setAuthed(true)
        })
        .catch((err: any) => {
          setLoading(false)
          setAuthed(false)
        });

    return () => {isActive = false}
  }, []);

  return (
    <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      {loading ?
        <Box sx={{display: 'flex', textAlign: 'center'}}>
          <CircularProgress sx={{margin: '0 auto 1rem'}} color='inherit' />
        </Box>
      :
        authed ?
          <>
            <CssBaseline />
            <NavHeader doLogout={handleLogout} setTheme={handleThemeChange} />
          </>
          :
          <Login onLoginSuccess={handleLogin} />}
      <Confirm />
      <PopupAlert />
    </ThemeProvider>
  );
}

export default Main;
