import React, { useState, useMemo } from 'react';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Paper, TablePagination, Box, Tooltip, IconButton, CircularProgress, TableSortLabel
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { MaintenanceRecord, MaintenanceType } from '../hooks/Interfaces';
import MaintenanceFormDialog from '../forms/MaintenanceFormDialog';
import { SendMessageFunction } from '../../../../../websocket/hooks/types';

interface MaintenanceTableProps {
	maintenance: MaintenanceRecord[];
	sendMessage: SendMessageFunction;
	type: 'Mold' | 'Machine';
	maintenanceTypes: MaintenanceType[];
	searchQuery: string;
}

const isKeyOfMaintenanceRecord = (key: string): key is keyof MaintenanceRecord => {
	return ['name', 'date', 'last_maintenance_type', 'parts_logged', 'performed_by'].includes(key);
}

const MaintenanceTable: React.FC<MaintenanceTableProps> = ({ maintenance, sendMessage, type, maintenanceTypes, searchQuery }) => {
	const [selectedRecord, setSelectedRecord] = useState<MaintenanceRecord | null>(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [sortBy, setSortBy] = useState<{ field: keyof MaintenanceRecord, order: 'asc' | 'desc' }>({ field: 'date', order: 'asc' });

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSort = (field: keyof MaintenanceRecord) => {
		const isAsc = sortBy.field === field && sortBy.order === 'asc';
		setSortBy({ field, order: isAsc ? 'desc' : 'asc' });
	};

	const sortedMaintenance = useMemo(() => {
		const stabilizedThis = [...maintenance];
		stabilizedThis.sort((a, b) => {
			let fieldA: any = a[sortBy.field];
			let fieldB: any = b[sortBy.field];

			if (sortBy.field === 'parts_logged') {
				fieldA = fieldA ?? 0;
				fieldB = fieldB ?? 0;
			} else {
				fieldA = fieldA ?? '';
				fieldB = fieldB ?? '';
			}

			if (fieldA < fieldB) {
				return sortBy.order === 'asc' ? -1 : 1;
			}
			if (fieldA > fieldB) {
				return sortBy.order === 'asc' ? 1 : -1;
			}
			return 0;
		});
		return stabilizedThis;
	}, [maintenance, sortBy]);

	const filteredMaintenance = useMemo(() => {
		return sortedMaintenance.filter((record) =>
			record.name.toLowerCase().includes(searchQuery.toLowerCase())
		);
	}, [sortedMaintenance, searchQuery]);

	const memoizedMaintenance = useMemo(() => {
		return filteredMaintenance
			.map((record) => ({
				...record,
				formattedDate: record.date ? formatDistanceToNow(parseISO(record.date), { addSuffix: true }) : 'N/A',
				localizedDate: record.date ? new Date(record.date).toLocaleString() : 'N/A',
				formattedPartsLogged: record.parts_logged !== null ? record.parts_logged.toLocaleString() : 'N/A'
			}));
	}, [filteredMaintenance]);

	return (
		<>
			{maintenance.length === 0 ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '400px' }}>
					<CircularProgress />
				</Box>
			) : (
				<>
					<TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'hidden', borderRadius: 3 }}>
						<Table stickyHeader aria-label={`${type.toLowerCase()}s table`}>
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center', fontWeight: 'bold' }}>
										<TableSortLabel
											active={sortBy.field === 'name'}
											direction={sortBy.field === 'name' ? sortBy.order : 'asc'}
											onClick={() => handleSort('name')}
										>
											{type} Name
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'date'}
											direction={sortBy.field === 'date' ? sortBy.order : 'asc'}
											onClick={() => handleSort('date')}
										>
											Last Maintenance
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'last_maintenance_type'}
											direction={sortBy.field === 'last_maintenance_type' ? sortBy.order : 'asc'}
											onClick={() => handleSort('last_maintenance_type')}
										>
											Maintenance Type
										</TableSortLabel>
									</TableCell>
									{type === 'Mold' && (
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
											<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
												<TableSortLabel
													active={sortBy.field === 'parts_logged'}
													direction={sortBy.field === 'parts_logged' ? sortBy.order : 'asc'}
													onClick={() => handleSort('parts_logged')}
												>
													Parts Logged
												</TableSortLabel>
												<Tooltip title="Parts Logged since last maintenance">
													<IconButton size="small" sx={{ marginLeft: 1 }}>
														<InfoIcon fontSize="inherit" />
													</IconButton>
												</Tooltip>
											</Box>
										</TableCell>
									)}
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
										<TableSortLabel
											active={sortBy.field === 'performed_by'}
											direction={sortBy.field === 'performed_by' ? sortBy.order : 'asc'}
											onClick={() => handleSort('performed_by')}
										>
											Performed By
										</TableSortLabel>
									</TableCell>
									<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{memoizedMaintenance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => (
									<TableRow key={record.id} sx={{ backgroundColor: record.needs_maintenance ? 'rgba(255, 0, 0, 0.3)' : 'inherit' }}>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center', fontWeight: 'bold' }}>{record.name}</TableCell>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center', cursor: 'default' }}>
											<Tooltip title={record.localizedDate || 'N/A'}>
												<span>{record.formattedDate}</span>
											</Tooltip>
										</TableCell>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>{record.last_maintenance_type}</TableCell>
										{type === 'Mold' && (
											<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>{record.formattedPartsLogged}</TableCell>
										)}
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>{record.performed_by}</TableCell>
										<TableCell sx={{ width: { xs: '100px', sm: '150px', md: '200px' }, textAlign: 'center' }}>
											<MaintenanceFormDialog
												selectedRecord={record}
												machineOrMold={type}
												sendMessage={sendMessage}
												maintenanceTypes={maintenanceTypes}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<TablePagination
						component="div"
						count={filteredMaintenance.length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}
		</>
	);
};

export default MaintenanceTable;
