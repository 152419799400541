import React, { FC, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
// icon imports
import ScheduleSend from '@mui/icons-material/ScheduleSend'
import Queue from '@mui/icons-material/Queue'
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline'
import Autorenew from '@mui/icons-material/Autorenew'
import Search from '@mui/icons-material/Search'
// custom imports
import { apiGet } from '../../../../utils/api'
import { colors } from '../../../../utils/colors'
import { scheduleToMachine, assignToArm, unassignFromArm, removeScheduledItem } from './MachineSchedulingApi'
import { api, DateFormat, useEffectApi } from '../../../../utils/globals'
import { doAlert } from '../../../../utils/globalComps/PopupAlert/PopupAlert'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import BaseContent from '../../../../utils/globalComps/BaseContent'
import BaseAccordion from '../../../../utils/globalComps/BaseAccordion'
import MachineLoadDisplay from '../../../../utils/globalComps/MachineLoadDisplay/MachineLoadDisplay'
import ApiTable from '../../../../utils/table/ApiTable'
import AssignToArm from '../../../../utils/modals/AssignToArm'
import ScheduleToMachine from '../../../../utils/modals/ScheduleToMachine'
import SetupsheetViewHelper from '../../management/setupsheets/SetupsheetViewHelper'
import FindCompatibleOrders from './FindCompatibleOrders/FindCompatibleOrders'
import OrderFilterForm from '../../management/orders/OrderFilterForm'

const URL: string = '/scheduling/machine-scheduling/'

const MachineScheduling: FC = () => {
  const theme = useTheme()

  const [loading, setLoading] = useState<boolean>(true)
  const [schedulePrimaryLoading, setSchedulePrimaryLoading] = useState<boolean>(false)
  const [refreshUnscheduledTable, setRefreshUnscheduledTable] = useState<boolean>(false)
  const [suppressUnscheduledLoadDisplay, setSuppressUnscheduledLoadDisplay] = useState<boolean>(false)
  const [machineList, setMachineList] = useState<any[]>([])
  const [assignToArmMove, setAssignToArmMove] = useState<boolean>(false)
  const [assignToArmMachineScheduled, setAssignToArmMachineScheduled] = useState<boolean>(false)
  const [assignToArmOrderItem, setAssignToArmOrderItem] = useState<number | undefined>(undefined)
  const [scheduleToMachineOrderItem, setScheduleToMachineOrderItem] = useState<number | undefined>(undefined)
  const [unscheduledOpen, setUnscheduledOpen] = useState<boolean>(false)
  const [moveScheduleItem, setMoveScheduleItem] = useState<boolean>(false)
  const [addScheduledItemType, setAddScheduleItemType] = useState<string>('')
  // this sucks... but gotta do it for setupsheet view
  const [setupsheetViewOpen, setSetupsheetViewOpen] = useState<boolean>(false)
  const [setupsheetItemName, setSetupsheetItemName] = useState<string>('')
  const [setupsheetMachineName, setSetupsheetMachineName] = useState<string>('')
  const [findCompatibleOrdersIdList, setFindCompatibleOrdersIdList] = useState<number[]>([])
  const [findCompatibleMachine, setFindCompatibleMachine] = useState<any>({})
  const [findCompatibleType, setFindCompatibleType] = useState<any>({})

  const [advancedScheduling, setAdvancedScheduling] = useState<any>(false)
  const [permissions, setPermissions] = useState<any>(undefined)

  const [unscheduledOrderFilterValues, setUnscheduledOrderFilterValues] = useState<any>({})

  useEffectApi(() => {

    document.title = 'Machine Scheduling | RotoEdgePro'

    setLoading(true)

    apiGet(URL, {machines: true}, (resp: any) => {
      // console.log('machine scheduling resp data :', resp.data)
      setPermissions(resp.data.permissions)
      setMachineList(resp.data.machines.map((machine: any) => ({...machine,
        refresh: false,
        suppressLoadDisplay: false,
        doLoad: false
      })))
      setLoading(false)
      setAdvancedScheduling(resp.data.advanced_scheduling)
    })

  }, [])

  // table actions
  const refreshMachine = (machineId: number | undefined, suppressLoadDisplay: boolean) => {
    if (machineId) {
      setMachineList([...machineList].map((machine: any) => {
        if (machine.id === machineId) {
          machine.refresh = !machine.refresh
          machine.suppressLoadDisplay = suppressLoadDisplay
        }

        return machine
      }))
    }
  }
  const setMachineDoLoad = (id: number | undefined, doLoad: boolean) => {
    if (id) {
      setMachineList([...machineList].map((machine: any) => {
        if (machine.id === id)
          machine.doLoad = doLoad

        return machine
      }))
    }
  }
  // this schedules unscheduled items that have primary machines assigned to the molds to their respective primary machines
  const autoSchedulePrimaryOrders = () => {
    setSchedulePrimaryLoading(true)

    api.post(URL, {autoSchedulePrimary: true})
      .then((resp: AxiosResponse) => {
        doAlert(resp.data.message, resp.data.status, true)
        setSchedulePrimaryLoading(false)
      })
      .catch((err: AxiosError) => {
        if (err.response) doAlert(err.response.data.message, 'error', true)
        setSchedulePrimaryLoading(false)
      })
  }

  // unscheduled order filter
  const onUnscheduledOrderFilter = (data: any) => {
    setUnscheduledOrderFilterValues({createdPastDate: moment(data.createdPastDate).format(DateFormat)})
  }

  // schedule to machine from unscheduled table
  const openScheduleToMachine = (orderItemId: number | undefined, status: any, stuff?: any | undefined) => {
    setScheduleToMachineOrderItem(orderItemId)

    setMoveScheduleItem(status.move)
    setAddScheduleItemType(status.type)
  }
  const closeScheduleToMachine = () => {
    setScheduleToMachineOrderItem(undefined)
  }
  const doScheduleToMachine = (orderItemId: number | undefined, machineId: number | undefined, move: boolean, addType?: string) => {
    scheduleToMachine(orderItemId, machineId, move, () => {
      for (const m of machineList) {
        refreshMachine(m.id, true)
      }
    }, addType)

    setSuppressUnscheduledLoadDisplay(true)
    setRefreshUnscheduledTable(!refreshUnscheduledTable)

    closeScheduleToMachine()
  }
  const doRemoveScheduledItem = (orderItemId: number | undefined, machineId: number | undefined, type?: string) => {
    removeScheduledItem(orderItemId, machineId, () => refreshMachine(machineId, true), type)
  }
  // assign machine scheduled items to an arm load queue
  const openAssignToArm = (orderItemId: number | undefined, machineScheduled: boolean, move: boolean) => {
    setAssignToArmOrderItem(orderItemId)
    setAssignToArmMachineScheduled(machineScheduled)
    setAssignToArmMove(move)
  }
  const closeAssignToArm = () => {
    setAssignToArmOrderItem(undefined)
  }
  const doAssignToArm = (orderItemId: number | undefined, assignData: any, machineId: number | undefined, callback: (data: any) => void) => {
    assignToArm(orderItemId, assignData, assignToArmMove, (data: any) => {
      callback(data)
      if (data.status === 'success') {
        closeAssignToArm()
        console.log(`refreshMachine(${machineId}, true)`)
        refreshMachine(machineId, true)
      }
    })
  }
  const doRemoveLoadQueue = (orderItemId: number | undefined, machineId: number | undefined, type: string) => {
    unassignFromArm(orderItemId, machineId, () => refreshMachine(machineId, true), type)
  }
  // assign machine scheduled item to an arm (advanced scheduling)
  const doRemoveArmScheduled = (oId: number | undefined, machineId: number | undefined) => {
    console.log('do remove arm scheduled')
  }
  // loading to the actual arm is done in enhanced load queue or from the arm loading page

  // setupsheet view
  const viewSetupsheet = (orderItem: any, machineName: string) => {
    setSetupsheetItemName(orderItem.item)
    setSetupsheetMachineName(machineName)
    setSetupsheetViewOpen(true)
  }
  const closeSetupsheetView = () => {
    setSetupsheetViewOpen(false)
  }

  // find compatible orders
  const findCompatibleHelper = (id: number, type: string, machine: any) => {
    setFindCompatibleMachine(machine)
    setFindCompatibleType(type)
    setFindCompatibleOrdersIdList([id])
  }
  const closeFindCompatibleOrders = () => {
    setFindCompatibleOrdersIdList([])
    setFindCompatibleMachine(undefined)
  }

  // helpers
  const machineExpand = (machineId: number, expanded: boolean) => {
    // console.log('machine expand :', machineId, expanded)
    if (expanded) {
      setMachineDoLoad(machineId, true)
      refreshMachine(machineId, false)
    } else {
      setMachineDoLoad(machineId, false)
    }
  }
  const handleUnscheduledOpenClose = (_: any, expanded: boolean) => setUnscheduledOpen(expanded)
  const getAssignedArmLegend = () => {
    if (advancedScheduling)
      return {color: colors[theme.palette.mode].table.row['arm_scheduled'], text: 'Arm Scheduled', tooltipText: 'Item is Scheduled to an Arm.'}
    else
      return {color: colors[theme.palette.mode].table.row['in_loadqueue'], text: 'In Load Queue', tooltipText: 'Item is in an Arm Load Queue.'}
  }

  // generate actions list for machine scheduled items
  const generateActions = (machine: any) => {

    const assignArmMessage: string = advancedScheduling ? 'Schedule to Arm' : 'Add to Load Queue'
    const removeArmMessage: string = advancedScheduling ? 'Unschedule from Arm' : 'Remove from Load Queue'
    const armCondition: string = advancedScheduling ? 'armScheduled' : 'loadQueue'

    // need to clean up serializers, shouldn't have to do this
    const mapObjectId = (object: any) => {
      console.log('mapObjectId :', object)
      switch (object.action_type) {
        case 'machineLoad': return object.id
        case 'loadQueue': return object.id
        case 'armScheduled': return object.arm_scheduled_id
        case 'scheduled': return object.machine_scheduled_id
      }
    }

    return [
      {
        icon: <Search />,
        action: (object: any) => findCompatibleHelper(mapObjectId(object), object.action_type, machine),
        actionType: 'object',
        text: 'Find Compatible Orders',
        permission: 'view'
      },
      {
        icon: <ScheduleSend />,
        action: (object: any) => openScheduleToMachine(mapObjectId(object), {add: true, type: object.action_type, move: false}, object),
        condition: 'action_type',
        conditionValue: 'scheduled',
        actionType: 'object',
        text: 'Schedule to additional Machine',
        permission: 'create'
      },
      {
        icon: <ScheduleSend />,
        action: (object: any) => openScheduleToMachine(mapObjectId(object), {type: object.action_type, move: true}, object),
        condition: 'action_type',
        conditionValue: 'scheduled',
        actionType: 'object',
        text: 'Move to different Machine',
        permission: 'edit'
      },
      {
        icon: <Queue />,
        action: (object: any) => openAssignToArm(mapObjectId(object), object.action_type === 'scheduled', false),
        condition: 'action_type',
        conditionValue: 'scheduled',
        actionType: 'object',
        text: assignArmMessage,
        permission: 'create'
      },
      {
        icon: <Queue />,
        action: (object: any) => openAssignToArm(mapObjectId(object), false, true),
        condition: 'action_type',
        conditionValue: armCondition,
        actionType: 'object',
        text: 'Move to different Arm',
        permission: 'edit'
      },
      {
        icon: <RemoveCircleOutline />,
        action: (object: any) => doRemoveLoadQueue(mapObjectId(object), machine.id, object.action_type),
        condition: 'action_type',   // this action type is different  (backend logic)
        conditionValue: armCondition,
        actionType: 'object',       // than this action type  (frontend logic)
        text: removeArmMessage,
        permission: 'delete'
      },
      {
        icon: <RemoveCircleOutline />,
        action: (object: any) => doRemoveScheduledItem(mapObjectId(object), machine.id, object.action_type),
        condition: 'action_type',
        conditionValue: 'scheduled',
        actionType: 'object',
        text: 'Unschedule',
        permission: 'delete',
        tooltip: 'Remove this scheduled item from this machine'}
      ,
    ]

  }

  const generateUnscheduledTableActions = () => {
    const actions: any[] = []

    if (permissions?.create)
      actions.push({text: 'Schedule Primary', icon: <Autorenew />, action: autoSchedulePrimaryOrders, tooltip: 'Auto schedule order items to their respective mold primary machines.', loading: schedulePrimaryLoading, loadingButton: true})

    return actions
  }

  return (
    <>
      <PageTitle title='Machine Scheduling' />
      <BaseContent loading={loading}>

        <>
          {machineList.map((machine: any, key: number) => (
            <BaseAccordion title={machine.name} key={key}
                           onChange={(e: any, expanded: boolean) => machineExpand(machine.id, expanded)}>

              <MachineLoadDisplay
                machine={machine}
                schedulingDisplay
                enhancedLoadQueue
                findCompatible
                forceRefresh={machine.refresh}
                doLoad={machine.doLoad}
                doRefresh={true}
                advancedScheduling={advancedScheduling}
                permissions={permissions}
                light
              />

              <ApiTable
                doLoad={machine.doLoad}
                legend={[
                  {color: colors[theme.palette.mode].table.row['loaded'], text: 'Loaded', tooltipText: 'Item is loaded to an Arm on the Machine.'},
                  getAssignedArmLegend(),
                  {color: colors[theme.palette.mode].table.row['scheduled'], text: 'Machine Scheduled', tooltipText: 'Item is scheduled to this Machine.'},
                  {color: colors[theme.palette.mode].table.row['no_setupsheet'], text: 'Missing Setupsheet', tooltipText: 'Item missing setupsheet for this Machine.'},
                ]}
                objectName=''
                headers={['Order', 'Item', 'Description', 'Arm', 'Ship Date', 'Balance', 'Loaded', 'Mold Volume', 'Oven Temperature', 'Oven Time', 'Takt Time', 'Mold Operational', '']}
                rowFields={['number', 'item', 'description', 'arm', 'ship_date', 'balance', 'mold_load_status', 'mold_volume',
                  'oven_temp', 'oven_time', 'takt_time', 'mold_operational']}
                rowActions={generateActions(machine)}
                rowClickActions={[
                  {field: 'item', action: (orderItem: number | undefined) => viewSetupsheet(orderItem, machine.name),
                   condition: 'has_setupsheet', tooltip: 'View Setupsheets'}
                ]}
                dataField='objects'
                url={`/scheduling/machine-scheduling/?machineId=${machine.id}&scheduled=${true}`}
                searchable
                orderable
                orderableBlacklist={['Ship Date']}
                refreshable
                refresh={machine.refresh}
                suppressLoadDisplay={machine.suppressLoadDisplay}
                // FormComponent={PermissionForm}
                // formParams={{permissionList: permissionList, extraInfo: extraInfo}}
              />

            </BaseAccordion>
          ))}
        </>

        <BaseAccordion title='Unscheduled Orders' onChange={handleUnscheduledOpenClose}>

          <OrderFilterForm onFilter={onUnscheduledOrderFilter} />
          <ApiTable
            doLoad={unscheduledOpen}
            objectName=''
            headers={['Order', 'Item', 'Description', 'Ship Date', 'Balance', '']}
            rowFields={['number', 'item', 'description', 'ship_date', 'moldable_balance']}
            tableActions={generateUnscheduledTableActions()}
            dateFilters={[
              {name: 'Created past date', tooltip: 'Filters orders created past this selected date', value: moment().format('MM/DD/YYYY')}
            ]}
            rowActions={[
              {icon: <ScheduleSend />, action: (oiId: number | undefined) => openScheduleToMachine(oiId, false), text: 'Schedule To Machine', noCondition: true, permission: 'create'}
            ]}
            dataField='objects'
            url={`/scheduling/machine-scheduling/`}
            extraParams={{unscheduled: true, ...unscheduledOrderFilterValues}}
            searchable
            orderable
            suppressLoadDisplay={!unscheduledOpen || suppressUnscheduledLoadDisplay}
            refresh={refreshUnscheduledTable}
          />

        </BaseAccordion>

      </BaseContent>

      <SetupsheetViewHelper open={setupsheetViewOpen} doClose={closeSetupsheetView} itemName={setupsheetItemName}
                            machineName={setupsheetMachineName} machineSelectable />
      <ScheduleToMachine orderItemId={scheduleToMachineOrderItem} machineList={machineList.map((m: any) => {return {id: m.id, title: m.name}})}
                         closeHandler={closeScheduleToMachine}
                         doSchedule={(orderItem, machineId) => doScheduleToMachine(orderItem, machineId, moveScheduleItem, addScheduledItemType)}
                         moveMachine={moveScheduleItem} addMachineItemType={addScheduledItemType} />
      <AssignToArm advancedScheduling={advancedScheduling} orderItemId={assignToArmOrderItem}
                   onClose={closeAssignToArm} onAssign={doAssignToArm} machineScheduled={assignToArmMachineScheduled}
                   move={assignToArmMove} />
      <FindCompatibleOrders orderIdList={findCompatibleOrdersIdList} onClose={closeFindCompatibleOrders}
                               machine={findCompatibleMachine} type={findCompatibleType} />
    </>
  )
}

export default MachineScheduling
export { scheduleToMachine }
