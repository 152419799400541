import React, { FC, useEffect, useState } from 'react'
// mui imports
import { useTheme } from '@mui/material/styles'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
// mui icon imports
import Remove from '@mui/icons-material/Remove'
// custom imports
import { colors } from '../../colors'
import { removeLoadAfter } from '../../../components/pages/scheduling/MachineScheduling/MachineSchedulingApi'
import { apiGet } from '../../api'
import BaseTooltip from '../BaseTooltip'

interface ItemCellProps {
  armId: number
  order?: any
  available: boolean
  availableAmount?: number
  availableMax?: number
  mode: string
  cellWidth: number
  removable?: boolean
  unloadOrderItem?: (name: string, id: number) => void
  onItemClick?: ((armId: number) => void) | undefined
  clickable?: boolean
}
const ItemCell: FC<ItemCellProps> = ({armId, order, available, availableAmount, availableMax,
                                       mode, cellWidth, removable, unloadOrderItem, onItemClick,
                                       clickable}) => {

  // colors
  const leftoverCellColor: string = colors[mode].machineLoadDisplay.armLeftOverCellColor
  let itemCellColor: string = colors[mode].machineLoadDisplay.itemCellColor

  if (order && order.balance === 0)
    itemCellColor = colors[mode].table.row.no_setupsheet
  else if ((order && order.balance <= 5))
    itemCellColor = colors[mode].machineLoadDisplay.almostDone

  const outlineColor = colors[mode].machineLoadDisplay.outlineColor

  const canClick = () => {
    return onItemClick && (clickable === undefined || clickable)
  }

  let content
  if (available)
    content = (
      <Slide direction='left' in={true}>
        <TableCell width='100%' style={{backgroundColor: leftoverCellColor, width: `${cellWidth}%`}}>
          <Grid container justifyContent='center'>
            <Grid item sx={{paddingTop: 0, marginTop: 0}}>{`${availableAmount} Available`}</Grid>
          </Grid>
        </TableCell>
      </Slide>
    )
  else
    content = (
      <Slide direction='left' in={true}>
        <TableCell sx={{backgroundColor: itemCellColor, width: `${cellWidth}%`, textAlign: 'center', cursor: canClick() ? 'pointer' : 'auto',
                        borderRight: `1px solid ${outlineColor} !important`}}
            onClick={() => canClick() && onItemClick ? onItemClick(armId) : {}}>
          <span>
            <Grid container spacing={0.5} justifyContent='center'>
              <Grid item xs={12} sx={{mt: '3px'}}>{order.item}</Grid>
              <Grid item xs={12}>{order.description}</Grid>
              <Grid item xs={12} sx={{mb: '3px'}}>
                {order.number}
                {removable ?
                  <span>
                  <BaseTooltip text='Unload order item'>
                    <IconButton size='small' edge='start' color='secondary' aria-label='menu' sx={{color: 'red', mt: '3px'}}
                                onClick={() => {if (unloadOrderItem)
                                  return unloadOrderItem(`${order.number} ${order.item}`, order.id)}}
                    >
                      <Remove />
                    </IconButton>
                  </BaseTooltip>
                  </span>
                  :
                  <></>}
              </Grid>
            </Grid>
          </span>
        </TableCell>
      </Slide>
    )

  return content
}

interface ItemQtyCellProps {
  quantity: number
}
const ItemQtyCell: FC<ItemQtyCellProps> = ({quantity}) => {
  const outlineColor = colors[useTheme().palette.mode].machineLoadDisplay.outlineColor

  return (
    <Slide direction='left' in={true}>
      <td style={{margin: '5px'}}>
        <Grid container justifyContent='center' style={{outline: `1px solid ${outlineColor}`}}>
          <Grid item>QTY: {quantity}</Grid>
        </Grid>
      </td>
    </Slide>
  )
}

interface OrderToLoadCellProps {
  machineLoadedOrderId: number
}
const OrderToLoadCell: FC<OrderToLoadCellProps> = ({machineLoadedOrderId}) => {
  const [ordersToLoadList, setOrdersToLoadList] = useState<any[]>([])

  const outlineColor = colors[useTheme().palette.mode].machineLoadDisplay.outlineColor

  const doRemoveLoadAfter = (machineLoadedOrderId: number, orderToRemoveId: number) => {
    removeLoadAfter(orderToRemoveId, machineLoadedOrderId, (removeId: number) => {
      console.log('do remove load after callback id :', removeId)
      setOrdersToLoadList([...ordersToLoadList].filter((order: any) => order.id !== removeId))
    })
  }

  useEffect(() => {
    let isActive = true

    apiGet(`/scheduling/enhanced-loadqueue/${machineLoadedOrderId}/`, {getOrdersToLoad: true}, (resp) => {
      setOrdersToLoadList(resp.data.orders_to_load)
    })

    return () => {isActive = false}
  }, [machineLoadedOrderId])

  if (ordersToLoadList.length > 0)
    return (
      <Slide direction='left' in={true}>
        <td style={{margin: '5px'}}>
          <Grid container justifyContent='center' style={{outline: `1px solid ${outlineColor}`}}>
            <Grid item xs={12} justifyContent='center'>
              <Typography sx={{textAlign: 'center', mt: '4px', fontWeight: 'bold'}}>Load After Queue</Typography>
            </Grid>
            {ordersToLoadList.map((orderToLoad: any, key: number) => (
              <React.Fragment key={key}>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  {orderToLoad.name}
                  <IconButton size='small' sx={{color: 'red'}} onClick={() => doRemoveLoadAfter(machineLoadedOrderId, orderToLoad.id)}>
                    <Remove />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </td>
      </Slide>
    )
  else
    return <></>
}

export {
  ItemCell, ItemQtyCell, OrderToLoadCell
}
