import React, {FC, useEffect} from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import FormInputDate from '../../../../utils/form/FormInputDate'
import {FormSpacing} from '../../../../utils/globals'
import Button from '@mui/material/Button'
import InfoText from '../../../../utils/globalComps/InfoText'

interface OrderFilterFormProps {
  onFilter: (data: any) => void
}

/**
 * Filters orders with created dates greater than the selected date
 * @param onFilter
 * @constructor
 */
const OrderFilterForm: FC<OrderFilterFormProps> = ({onFilter}) => {

  // filter methods
  const dateSixMonthsAgo = () => {
    const date: Date = new Date()
    return date.setMonth(date.getMonth() - 6)
  }
  const defaults: any = {
    createdPastDate: dateSixMonthsAgo()
  }
  const methods: any = useForm({defaultValues: defaults})
  const { handleSubmit, reset } = methods

  useEffect(() => {

  }, [onFilter])

  const doReset = () => {
    reset(defaults)
  }

  return (
    <FormProvider {...methods}>
      <Grid container spacing={FormSpacing} sx={{mt: 1}}>
        <Grid container item xs={6} justifyContent='left' spacing={FormSpacing}>
          <Grid item sx={{ml: 2}}>
            <FormInputDate name='createdPastDate' label='Created Past Date' placeholder='Created Past Date' />
          </Grid>
          <InfoText text='Displays orders created after this date. (Initially loads to 6 months ago)' />
        </Grid>
        <Grid container item xs={6} justifyContent='right' spacing={FormSpacing} sx={{pr: 2}}>
          <Grid item>
            <Button variant='contained' onClick={handleSubmit(onFilter)}>Submit</Button>
          </Grid>
          <Grid item>
            <Button variant='outlined' onClick={doReset}>Reset</Button>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default OrderFilterForm