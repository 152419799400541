import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box, Typography, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as XLSX from 'xlsx';
import { FileUploader } from 'react-drag-drop-files';
import HeaderExampleDialog from './HeaderExampleDialog'; // Adjust the import path as needed
import { SendMessageFunction } from '../hooks/types';

interface ExcelImportDialogProps {
	buttonSx?: object;
	buttonText?: string
	requiredHeaders: string[];
	sendMessage: SendMessageFunction;
}

const fileTypes = ["xlsx", "xls"];

const ExcelImportDialog: React.FC<ExcelImportDialogProps> = ({ buttonSx, buttonText, requiredHeaders, sendMessage }) => {
	const [open, setOpen] = useState(false);
	const [exampleOpen, setExampleOpen] = useState(false);
	const [fileName, setFileName] = useState<string | null>(null);
	const [excelData, setExcelData] = useState<any[]>([]);
	const [headerError, setHeaderError] = useState<string | null>(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleExampleOpen = () => {
		setExampleOpen(true);
	};

	const handleExampleClose = () => {
		setExampleOpen(false);
	};

	const handleClose = () => {
		setOpen(false);
		setFileName(null);
		setExcelData([]);
		setHeaderError(null);
	};

	const handleFileUpload = (file: File) => {
		setFileName(file.name);
		const reader = new FileReader();
		reader.onload = (e: ProgressEvent<FileReader>) => {
			const arrayBuffer = e.target?.result as ArrayBuffer;
			const data = new Uint8Array(arrayBuffer);
			const binaryString = data.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
			const workbook = XLSX.read(binaryString, { type: 'binary' });
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];
			const jsonData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
			validateHeaders(jsonData[0] as string[]);
			setExcelData(jsonData as any[]);
		};
		reader.readAsArrayBuffer(file);
	};

	const validateHeaders = (headers: string[]) => {
		const missingHeaders = requiredHeaders.filter(header => !headers.includes(header));
		const incorrectHeaders = headers.filter((header: string) => !requiredHeaders.includes(header) && header !== "");

		if (missingHeaders.length > 0 || incorrectHeaders.length > 0) {
			let errorMessage = "Header Errors:\n";
			if (missingHeaders.length > 0) {
				errorMessage += `  Missing: ${missingHeaders.map(header => `"${header}"`).join(', ')}\n`;
			}
			if (incorrectHeaders.length > 0) {
				errorMessage += `  Found: ${incorrectHeaders.map((header: string) => `"${header}"`).join(', ')}\n`;
			}
			setHeaderError(errorMessage);
		} else {
			setHeaderError(null);
		}
	};

	const handleSubmit = () => {
		if (headerError) {
			return;
		}
		// Handle data submission to the backend
		console.log('Excel Data:', excelData);
		sendMessage('import_excel', { excel_data: excelData });
		handleClose();
	};

	return (
		<>
			<Button variant="outlined" color="primary" onClick={handleClickOpen} sx={buttonSx}>
				{buttonText ? buttonText : 'Import Excel'}
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={false}>
				<DialogTitle>
					Import Excel
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column', my: 2 }}>
						<FileUploader handleChange={handleFileUpload} name="file" types={fileTypes} />
						{fileName && (
							<Typography variant="body1" sx={{ mt: 2 }}>
								Uploaded File: {fileName}
							</Typography>
						)}
						{headerError && (
							<Alert severity="error" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
								{headerError}
							</Alert>
						)}
					</Box>
				</DialogContent>
				<DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box>
						<Button onClick={handleExampleOpen} variant='outlined' color="primary">
							Example
						</Button>
					</Box>
					<Box>
						<Button onClick={handleClose} color="error" sx={{ mr: 1, px: 2, py: 1, borderRadius: 2 }}>
							Cancel
						</Button>
						<Button onClick={handleSubmit} color="primary" variant="contained" disabled={!!headerError}>
							Submit
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
			<HeaderExampleDialog open={exampleOpen} onClose={handleExampleClose} headers={requiredHeaders} />
		</>
	);
};

export default ExcelImportDialog;
