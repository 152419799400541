import React, { FC } from 'react';
// mui imports
import Grid from '@mui/material/Grid';
// custom imports
import FormInputText from '../../../../utils/form/FormInputText';
import FormInputNumber from '../../../../utils/form/FormInputNumber';
import FormInputSwitch from '../../../../utils/form/FormInputSwitch';
import FormInputDropdown from '../../../../utils/form/FormInputDropdown';
import ApiForm from '../../../../utils/form/ApiForm';
import { FormSpacing } from '../../../../utils/globals';

interface MachineFormProps {
  id: number | undefined;
  open: boolean;
  closeForm: (submit: boolean, data: any, create: boolean) => void;
  factories: any[];
  factory: string | undefined;
}
/**
 * MachineForm component
 * @param id
 * @param open
 * @param closeForm
 * @param factories
 * @param factory
 */
const MachineForm: FC<MachineFormProps> = ({id, open, closeForm, factories, factory}) => {

  const fetchUrl: string = id ? `/inventory/machines/${id}/` : '';
  const submitUrl: string = '/inventory/machines/';

  return (
    <ApiForm
      name='Machine Form'
      open={open}
      defaultValues={{factory: factory, name: '', arms_per_shift: '', rounds_per_shift: '', logging_interval: '',
        percent_target: '', disabled: false, hide_avail_space: false}}
      closeForm={closeForm}
      id={id}
      fetchUrl={fetchUrl}
      submitUrl={submitUrl}
      timeFields={[]}
    >
      <Grid container spacing={FormSpacing}>
        <Grid item xs={6}><FormInputDropdown name='factory_id' label='Factory' options={factories} /></Grid>
        <Grid item xs={4}><FormInputText name='name' label='Name' /></Grid>
        <Grid item xs={2}><FormInputText name='code' label='Code' /></Grid>
        <Grid item xs={3}><FormInputNumber name='arms_per_shift' label='Arms/Shift' /></Grid>
        <Grid item xs={3}><FormInputNumber name='rounds_per_shift' label='Rounds/Shift (MRP)' /></Grid>
        <Grid item xs={3}><FormInputNumber name='logging_interval' label='Logging interval (minutes)' /></Grid>
        <Grid item xs={3}><FormInputNumber name='percent_target' label='% target' /></Grid>
        <Grid item xs={4}><FormInputSwitch name='hide_avail_space' label='Hide available space' /></Grid>
        <Grid item xs={4}><FormInputSwitch name='disabled' label='Disabled' /></Grid>
        <Grid item xs={4}><FormInputSwitch name='dual_oven_times' label='Dual Oven Times' /></Grid>
      </Grid>
    </ApiForm>
  );
}

export default MachineForm;
