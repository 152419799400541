import { ApexOptions } from "apexcharts";
import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import InfoTooltip from "./InfoTooltip";

interface SemiCircleGaugeProps {
  value: number | null;
  color?: string;
  label: string;
  height: number;
  trackColor?: string;
	tooltip?: { [key: string]: any } | undefined
	onClick?: () => void
}

const SemiCircleGauge: React.FC<SemiCircleGaugeProps> = ({ value, color, label, height, trackColor, tooltip, onClick }) => {

  const isDisabled = value === null;

	const trackBackground: string = useMemo(() => {
		const disabledColor = trackColor ? trackColor : "#f0f0f0"
		return isDisabled ? disabledColor : trackColor || "#e7e7e7"
	}, [trackColor, isDisabled])

  const statusColor = useMemo(() => {
    if (isDisabled) return "#CCCCCC"; // Grey color when disabled
    if (value! > 80) return "#00FF00"; // Green
    if (value! > 60) return "#FFFF00"; // Yellow
    return "#FF0000"; // Red
  }, [value, isDisabled]);

  const activeColor = color || statusColor;

  const chartOptions: ApexOptions = {
    chart: {
      type: "radialBar",
      offsetY: -20,
    },
    plotOptions: {
      radialBar: {
        startAngle: -110,
        endAngle: 110,
        hollow: {
          margin: 5,
          size: "65%",
          background: "transparent",
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: "#111",
            fontSize: "14px",
          },
          value: {
            offsetY: 16,
            color: "#111",
            fontSize: "22px",
            formatter: function (val: number) {
              return isDisabled ? 'N/A' : `${val}%`;
            },
          },
        },
        track: {
          background: trackBackground,
          strokeWidth: '150%',
          margin: -2, // margin is in pixels
        },
      },
    },
    fill: {
      colors: [activeColor],
    },
    labels: [label],
    series: isDisabled ? [0] : [value as number],		
  };

  const opacity = isDisabled ? 0.5 : 1; // Reduce opacity when disabled

  return (
		tooltip && !isDisabled ? (
			<InfoTooltip tooltip={tooltip}>
				<div style={{ cursor: onClick ? 'pointer' : 'default', opacity: opacity }} onClick={onClick}>
					<ReactApexChart
						options={chartOptions}
						series={chartOptions.series}
						type="radialBar"
						height={height}
					/>
				</div>
			</InfoTooltip>
		) : (
				<div onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'default', opacity: opacity }}>
				<ReactApexChart
					options={chartOptions}
					series={chartOptions.series}
					type="radialBar"
					height={height}
				/>
			</div>
		)
  );
};

export default SemiCircleGauge;
