// React imports
import React, { FC, useState, useEffect } from 'react'
// MUI imports
import DialogContentText from '@mui/material/DialogContentText'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Grid from '@mui/material/Grid'
// MUI form
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
// Icon imports
import Logout from '@mui/icons-material/Logout'
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings'
import BugReportIcon from '@mui/icons-material/BugReport'
import Print from '@mui/icons-material/Print'
import Settings from '@mui/icons-material/Settings'
// custom imports
import { api, ApiUrl } from '../../utils/globals'
import { getAccountId } from '../../utils/auth'
import BaseModal from '../../utils/modals/BaseModal'
import Button from "@mui/material/Button";
import LabelPrinterSettingsModal from "../../utils/labelPrinting/LabelPrinterSettingsModal";
import ConfigSettingsModal from "./ConfigSettingsModal";
import NotificationPreferences from '../pages/services/Accounts/Notifications/NotificationPreferences'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='left' ref={ref} {...props} />
})

interface AccountMenuProps {
  doLogout: () => void
  isOpen: boolean
  triggerParentUpdate: (state: boolean) => void
  changeTheme: (theme: string) => void
  navOpen: () => void
  dark: boolean
  drawerOpen: boolean
  userName: string
  isSuperUser: boolean
  isAdmin: boolean
  setDrawerStickOpen: (open: boolean) => void
  labelPrintingEnabled: boolean
}
/**
 * AccountMenu component
 * @param doLogout Logs the user out
 * @param isOpen Open flag for the modal
 * @param triggerParentUpdate Update parent modal with open status
 * @param changeTheme Change the app theme account preference (light/dark)
 * @param navOpen Change the navbar account preference
 * @param dark Default dark theme account pref
 * @param drawerOpen Default drawer account pref
 * @param userName Display name for menu
 * @param isSuperUser
 * @param isAdmin
 * @param setDrawerStickOpen
 * @param labelPrintingEnabled
 * @constructor
 */
const AccountMenu: FC<AccountMenuProps> = ({doLogout, isOpen, triggerParentUpdate, changeTheme, navOpen,
                                             dark, drawerOpen, userName, isSuperUser,
                                             isAdmin, setDrawerStickOpen, labelPrintingEnabled}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [navDrawerOpen, setNavDrawerOpen] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [configSettingsOpen, setConfigSettingsOpen] = useState<boolean>(false)
  const [printerSettingsOpen, setPrinterSettingsOpen] = useState<boolean>(false)

  // css
  const modalPosition = {position: 'absolute', right: 10, top: 20}
  // menu state
  const handleOpenState = (state: boolean) => triggerParentUpdate(state)
  const handleMenuClose = () => handleOpenState(false)

  const patchAccount = (data: any) => {
    data['edit'] = true
    api.post(`/main/accounts/${getAccountId()}/`, data)
  }
  // parent state
  const handleDarkTheme = () => { changeTheme('dark'); patchAccount({'dark_mode': true}) }
  const handleLightTheme = () => { changeTheme('light'); patchAccount({'dark_mode': false}) }

  // helpers
  const handleNavDrawerChange = () => {
    console.log('drawer stick open (1) :', !navDrawerOpen)
    setDrawerStickOpen(!navDrawerOpen)
    setNavDrawerOpen(!navDrawerOpen)
    navOpen()
    patchAccount({'drawer_open': !navDrawerOpen})
  }
  const openConfigSettings = () => setConfigSettingsOpen(true)
  const closeConfigSettings = () => setConfigSettingsOpen(false)
  const openPrinterSettings = () => setPrinterSettingsOpen(true)
  const closePrinterSettings = () => setPrinterSettingsOpen(false)

  const generateActions = () => {
    let actionList: any[] = [{text: 'Logout', outlined: true, icon: <Logout />, action: doLogout, left: true}]
		actionList.push({outlined: false, icon: <BugReportIcon />, url: `https://forms.bluecatreports.com/8uPk0gRB/report-an-issue`, text: 'Bug Report'})

    if (isSuperUser)
      actionList.push({outlined: false, icon: <AdminPanelSettings />, url: `${ApiUrl}/admin`, text: 'Admin'})
    return actionList

  }

  useEffect(() => {

    setOpen(isOpen)
    setNavDrawerOpen(drawerOpen)
    setUsername(userName)

  }, [isOpen, drawerOpen, userName, setOpen, setNavDrawerOpen, setUsername])

  return (
    <>
      <BaseModal
        title='Account Preferences'
        open={open}
        closeHandler={handleMenuClose}
        closeButton
        dividers
        keepMounted
        actions={generateActions()}
        maxWidth='xs'
        transition={Transition}
        paperProps={{ sx: modalPosition }}
      >
        <Grid container>
          <Grid item xs={12}>
            <DialogContentText component='h6' sx={{textTransform: 'capitalize'}}>Account: {username}</DialogContentText>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant='standard' fullWidth={true} sx={{marginTop: '1rem'}}>
              <FormLabel component='legend'>Select theme</FormLabel>
              <FormGroup>
                <FormControlLabel control={<Switch checked={!dark} onChange={handleLightTheme} name='light'/>}
                                  label='Light Theme' />
                <FormControlLabel control={<Switch checked={dark} onChange={handleDarkTheme} name='dark'/>}
                                  label='Dark Theme' />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant='standard' fullWidth={true} sx={{marginTop: '1rem'}}>
              <FormLabel component='legend'>Start with navigation drawer open</FormLabel>
              <FormGroup>
                <FormControlLabel control={<Switch checked={navDrawerOpen} onChange={handleNavDrawerChange} name='nav'/>}
                                  label='Navigation Drawer default open' />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid container item xs={12} spacing={1} sx={{mt: 1}}>
            {/* {isAdmin || isSuperUser ?
              <Grid container item xs={6} justifyContent='center'>
                <Button variant='contained' startIcon={<Settings />} onClick={openConfigSettings}>Config Settings</Button>
              </Grid>
              :
              <></>
            } */}
            <Grid container item xs={isAdmin || isSuperUser ? 6 : 12} justifyContent='center'>
              <Button variant='contained' startIcon={<Print />} onClick={openPrinterSettings}>Label Printing</Button>
            </Grid>
            <Grid container item xs={isAdmin || isSuperUser ? 6 : 12} justifyContent='center'>
							<NotificationPreferences />
            </Grid>
          </Grid>
        </Grid>
      </BaseModal>
      <LabelPrinterSettingsModal open={printerSettingsOpen} onClose={closePrinterSettings} />
      <ConfigSettingsModal open={configSettingsOpen} onClose={closeConfigSettings} />
    </>
  )
}

export default AccountMenu
