import React, { FC, useState } from 'react'
import moment from 'moment'
// mui imports
import { useTheme } from '@mui/material/styles'
// mui icon imports
import ScheduleSend from '@mui/icons-material/ScheduleSend'
import LibraryBooks from '@mui/icons-material/LibraryBooks'
import AccessTime from '@mui/icons-material/AccessTime'
import Add from '@mui/icons-material/Add'
import Close from '@mui/icons-material/Close'
// custom imports
import { ALL } from '../../../../utils/globalComps/ProductionLog/ProductionLogHelpers'
import { DateFormat, useEffectApi } from '../../../../utils/globals'
import { scheduleToMachine } from '../../scheduling/MachineScheduling/MachineScheduling'
import { colors } from '../../../../utils/colors'
import { doConfirm } from '../../../../utils/modals/Confirm'
import { doSetOrderStatus, getData, getOrderData, logProduction } from './OrderApi'
import ApiTable from '../../../../utils/table/ApiTable'
import PageTitle from '../../../../utils/globalComps/PageTitle'
import OrderForm from './OrderForm'
import ScheduleToMachine from '../../../../utils/modals/ScheduleToMachine'
import ProductionLogModal from '../../../../utils/globalComps/ProductionLog/ProductionLogModal'
import LogProduction from '../../../../utils/globalComps/LogProduction/LogProduction'
import ScheduleToLineplan from '../../../../utils/globalComps/ScheduleToLineplan/ScheduleToLineplan'
import SetupsheetViewHelper from '../setupsheets/SetupsheetViewHelper'
import OrderFilterForm from './OrderFilterForm'

const url = '/orders/orders'
const OPEN: number = 0
const CLOSED: number = 1

const MOLDING: number = 1
const SECONDARY: number = 2
const FOAMING: number = 3
const TYPE_NAMES: string[] = ['Molding', 'Secondary', 'Foaming']

/**
 * Order list management table
 */
const Orders: FC = () => {
  const theme = useTheme()

  const [data, setData] = useState<any[]>([])
  const [foamingEnabled, setFoamingEnabled] = useState<boolean>(false)
  const [machineList, setMachineList] = useState<any[]>([])
  const [itemList, setItemList] = useState<any[]>([])
  const [scheduleToMachineOrderItem, setScheduleToMachineOrderItem] = useState<number | undefined>(undefined)
  const [scheduleToLineplanOrderItem, setScheduleToLineplanOrderItem] = useState<number | undefined>(undefined)
  const [suppressLoad, setSuppressLoad] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)

  const [scrapCodeList, setScrapCodeList] = useState<any[]>([])
  const [logProductionItems, setLogProductionItems] = useState<any[]>([])
  const [logProductionErrors, setLogProductionErrors] = useState<any[]>([])
  const [logProductionHideAvailable, setLogProductionHideAvailable] = useState<boolean>(false)
  const [logProductionType, setLogProductionType] = useState<number>(MOLDING)
  const [logProductionTypeName, setLogProductionTypeName] = useState<string | undefined>(undefined)

  const [productionLogsOpen, setProductionLogsOpen] = useState<boolean>(false)
  const [productionLogsOrderId, setProductionLogsOrderId] = useState<number | undefined>(undefined)

  const [setupsheetViewOpen, setSetupsheetViewOpen] = useState<boolean>(false)
  const [setupsheetItemName, setSetupsheetItemName] = useState<string>('')

  const [filterValues, setFilterValues] = useState<any>({})

  const refreshTable = (suppressLoad: boolean) => {
    setSuppressLoad(suppressLoad)
    setRefresh(!refresh)
  }

  useEffectApi(() => {

    document.title = 'Orders | RotoEdgePro'

    getData((data: any) => {
      setFoamingEnabled(data.foamingEnabled)
      setMachineList(data.machines)
      setScrapCodeList(data.scrapCodes)
      setItemList(data.itemList)
    })

  }, [])

  // row actions
  const openScheduleToLineplan = (orderItemId: number | undefined) => setScheduleToLineplanOrderItem(orderItemId)
  const closeScheduleToLineplan = () => setScheduleToLineplanOrderItem(undefined)
  const openScheduleToMachine = (orderItemId: number | undefined) => setScheduleToMachineOrderItem(orderItemId)
  const closeScheduleToMachine = () => setScheduleToMachineOrderItem(undefined)
  const doScheduleToMachine = (orderItemId: number | undefined, machineId: number | undefined) => {
    scheduleToMachine(orderItemId, machineId, false, () => {
      // prompt the user to schedule to a lineplan after scheduling to a machine
      openScheduleToLineplan(orderItemId)
    })
    closeScheduleToMachine()
  }
  const setOrderStatus = (open: boolean, orderId: number) => {
    doConfirm(`${open? 'Open' : 'Close'} ${getOrderName(orderId)}?`, () => {
      doSetOrderStatus(orderId, open ? OPEN : CLOSED, () => {
        refreshTable(true)
      })
      console.log(`set order ${open? 'open' : 'closed'}`)
    })
  }

  // row click actions
  /**
   * @param id: order id
   */
  const viewSetupsheet = (order: any) => {
    if (order !== undefined) {
      setSetupsheetViewOpen(true)
      setSetupsheetItemName(order.item)
    }
  }
  const closeSetupsheet = () => {
    setSetupsheetViewOpen(false)
    setSetupsheetItemName('')
  }

  // helpers
  const getHeaders = () => {
    let headers = ['Number', 'Item', 'Description', 'Ship Date', 'Quantity', 'Molded', 'Secondary', 'Foamed',
      'Assembled', 'Shipped', 'Type', 'Created', 'Closed', '']

    if (!foamingEnabled)
      headers.filter((header: string) => header === 'Foamed')

    return headers
  }
  const getRowFields = () => {
    let rowFields = ['full_number', 'item', 'description', 'ship_date', 'quantity', 'molded_count', 'secondary_count',
      'foamed_count', 'assembled_count', 'shipped_count', 'type', 'created_date', 'closed_date']

    if (!foamingEnabled)
      rowFields.filter((rowField: string) => rowField === 'foamed_count')

    return rowFields
  }
  const handleData = (_data: any[]) => {
    console.log('handle data :', _data)
    setData([...data].concat(_data))
  }
  const getOrderName = (orderId: number) => {
    for (const order of data)
      if (order.id === orderId)
        return `${order.number} ${order.item}`
  }

  // production logging and logs
  const openLogProduction = (id: number, type: number) => {
    setLogProductionType(type)
    setLogProductionTypeName(TYPE_NAMES[type-1])
    setLogProductionHideAvailable(type === MOLDING)
    console.log(`setLogProductionHideAvailable(${type === MOLDING})`)
    getOrderData(id, type, (data: any) => {
      console.log('get order data :', data.object)
      setLogProductionItems([data.object])
    })
  }
  const closeLogProduction = () => setLogProductionItems([])

  const doLogProduction = (data: any) => {
    logProduction(logProductionType, data, (data: any) => {
      console.log('log production resp data :', data)
      if (data.status === 'success') {
        closeLogProduction()
      } else if (data.status === 'error') {
        setLogProductionErrors(data.errors)
      }
    })
  }
  const openProductionLogs = (id: number) => {
    setProductionLogsOpen(true)
    setProductionLogsOrderId(id)
  }
  const closeProductionLogs = () => {
    setProductionLogsOpen(false)
    setProductionLogsOrderId(undefined)
  }

  // filter form
  const onFilter = (data: any) => {
    setFilterValues({createdPastDate: moment(data.createdPastDate).format(DateFormat)})
  }

  const getRowColor = (color: string) => {
    if (theme.palette.mode === 'dark')
      return colors.dark.table.row[color]
    else
      return colors.light.table.row[color]
  }

  return (
    <>
      <PageTitle title='Orders Management' legend={[
        {
          color: getRowColor('secondary'),
          text: 'Completed',
          tooltipText: 'Completed orders.'
        },
        {
          color: getRowColor('loaded'),
          text: 'Active',
          tooltipText: 'Orders loaded to a machine, or on a lineplan live table.'
        },
        {
          color: getRowColor('in_loadqueue'),
          text: 'Scheduled',
          tooltipText: 'Orders scheduled to a machine, or on a lineplan staging table.'
        },
        {
          color: getRowColor('no_setupsheet'),
          text: 'Needs post molding',
          tooltipText: 'Orders that have completed the molding stage, but need additional molding.'
        },
        {
          color: getRowColor('foamed'),
          text: 'Inactive',
        },
      ]} />
      <OrderFilterForm onFilter={onFilter} />
      <ApiTable
        tableName=''
        refresh={refresh}
        suppressLoadDisplay={suppressLoad}
        objectName='Order'
        titleVariant=''
        headers={getHeaders()}
        rowFields={getRowFields()}
        rowActions={[
          {icon: <ScheduleSend />, action: openScheduleToMachine, text: 'Schedule To Machine', condition: ['machine_scheduleable', 'machine_scheduled'], conditionValue: [true, false], permission: 'edit'},
          {icon: <ScheduleSend />, action: openScheduleToMachine, text: 'Re-Schedule To Machine', condition: ['machine_scheduleable', 'machine_scheduled'], conditionValue: [true, true], permission: 'edit'},
          {icon: <ScheduleSend />, action: openScheduleToLineplan, text: 'Schedule to Lineplan', condition: ['line_scheduleable', 'line_scheduled'], conditionValue: [true, false], permission: 'edit'},
          {icon: <ScheduleSend />, action: openScheduleToLineplan, text: 'Re-Schedule to Lineplan', condition: ['line_scheduleable', 'line_scheduled'], conditionValue: [true, true], permission: 'edit'},
          {icon: <AccessTime />, action: (id: number) => openLogProduction(id, MOLDING), text: 'Log Production Molding', condition: 'can_log_molding', conditionValue: true},
          {icon: <AccessTime />, action: (id: number) => openLogProduction(id, SECONDARY), text: 'Log Production Secondary', condition: 'can_log_secondary', conditionValue: true},
          {icon: <AccessTime />, action: (id: number) => openLogProduction(id, FOAMING), text: 'Log Production Foaming', condition: 'can_log_foaming', conditionValue: true},
          {icon: <LibraryBooks />, action: openProductionLogs, text: 'Production Logs', noCondition: true},
          {icon: <Add />, action: (id: number) => setOrderStatus(true, id), text: 'Open Order', condition: 'status', conditionValue: 'Closed', permission: 'create'},
          {icon: <Close />, action: (id: number) => setOrderStatus(false, id), text: 'Close Order', condition: 'status', conditionValue: 'Open', permission: 'delete'},
        ]}
        rowClickActions={[
          {field: 'item', action: viewSetupsheet, tooltip: 'View Setupsheets', condition: 'has_setupsheet'}
        ]}
        extraParams={filterValues}
        dataField='objects'
        url={url}
        filterable
        noAllFilter
        filters={[{text: 'Open'}, {text: 'Closed'}]}
        refreshable
        orderable
        orderableBlacklist={['Molded', 'Secondary', 'Foamed', 'Assembled', 'Shipped']}
        searchable
        creatable
        editable
        deletable
        exportable
        dataCallback={handleData}
        FormComponent={OrderForm}
        formParams={{itemList: itemList}}
      />
      <ScheduleToMachine orderItemId={scheduleToMachineOrderItem} closeHandler={closeScheduleToMachine}
                         doSchedule={doScheduleToMachine} machineList={machineList.map((machine: any) => {return {id: machine.id, title: machine.name}})} />
      <ScheduleToLineplan id={scheduleToLineplanOrderItem} closeHandler={closeScheduleToLineplan}/>


      <LogProduction
        data={logProductionItems}
        onClose={closeLogProduction}
        onLog={doLogProduction}
        errors={logProductionErrors}
        scrapCodeList={scrapCodeList}
        nonConforming
        hideAvailable={logProductionHideAvailable}
        typeName={logProductionTypeName}
      />

      <ProductionLogModal open={productionLogsOpen} type={ALL} onClose={closeProductionLogs}
                          orderId={productionLogsOrderId} allowTypeSelect />
      <SetupsheetViewHelper open={setupsheetViewOpen} doClose={closeSetupsheet} itemName={setupsheetItemName}
                            machineSelectable />
    </>
  )
}

export default Orders
