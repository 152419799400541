import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTheme } from "@mui/material";

interface ScrapPieChartProps {
	conforming: number;
	nonConforming: number;
	conformingParts: number;
	nonConformingParts: number;
	height: number;
}

const ScrapPieChart: React.FC<ScrapPieChartProps> = ({
	conforming,
	nonConforming,
	conformingParts,
	nonConformingParts,
	height
}) => {
	const theme = useTheme();

	const lightenColor = (hex: string, percent: number) => {
		let r = parseInt(hex.slice(1, 3), 16);
		let g = parseInt(hex.slice(3, 5), 16);
		let b = parseInt(hex.slice(5, 7), 16);

		r = Math.min(255, Math.floor(r + (255 - r) * percent / 100));
		g = Math.min(255, Math.floor(g + (255 - g) * percent / 100));
		b = Math.min(255, Math.floor(b + (255 - b) * percent / 100));

		return `rgb(${r}, ${g}, ${b})`;
	};

	const chartOptions: ApexOptions = useMemo(() => {
		return {
			chart: {
				type: "pie",
			},
			labels: ["Conforming Parts", "Non-Conforming Parts"],
			colors: [theme.palette.primary.main, theme.palette.error.main],
			legend: {
				position: 'bottom'
			},
			dataLabels: {
				enabled: true,
				style: {
					fontSize: '22px',
					colors: [
						lightenColor(theme.palette.primary.main, 20),
						lightenColor(theme.palette.error.main, 20)
					]
				},
				background: {
					enabled: true,
					foreColor: theme.palette.common.white,
					borderRadius: 2,
					padding: 4,
					opacity: 1,
					dropShadow: {
						enabled: true,
						top: 1,
						left: 1,
						blur: 1,
						opacity: 0.45
					}
				},
				formatter: function (val, opts) {
					return `${opts.w.config.series[opts.seriesIndex]}%`;
				},
			},
			tooltip: {
				y: {
					formatter: function (val, { seriesIndex }) {
						const parts = seriesIndex === 0 ? conformingParts : nonConformingParts;
						return `${val}%`;
						// return `${val}% (${parts} parts)`;
					}
				}
			}, 
			plotOptions: {
				pie: {
					donut: {
						labels: {
							// show: true,
						},
					},
					dataLabels: {
						minAngleToShowLabel: 0, // Ensure all labels show, even for small slices
					},
				},
			},
		};
	}, [conformingParts, nonConformingParts, theme]);
	
	const series = useMemo(() => {
		return [conforming || 0, nonConforming || 0];
	}, [conforming, nonConforming]);

	if (!chartOptions || !series) {
		return null; // Optionally render a loader or an empty state
	}

	return (
		<ReactApexChart
			options={chartOptions}
			series={series}
			type="pie"
			height={height}
		/>
	);
};

export default ScrapPieChart;
