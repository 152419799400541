import React, {FC, useEffect, useState} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
// mui imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
// custom imports
import BaseModal from '../../modals/BaseModal'
import FormInputNumber from '../../form/FormInputNumber'
import FormInputDropdown from '../../form/FormInputDropdown'
import FormInputSwitch from '../../form/FormInputSwitch'
import Button from '@mui/material/Button'

const MAX_WIDTH: number = 100
const DESC_LENGTH: number = 20

interface LogProductionProps {
  data: any
  onLog: (data: any) => void
  onClose: () => void
  machine?: boolean
  nonConforming?: boolean
  errors: {name: never, message: string}[]
  scrapCodeList: any[]
  ncOnly?: boolean | undefined
  hideAvailable?: boolean | undefined
  typeName?: string | undefined
  incDecLogic?: boolean | undefined
}
const LogProduction: FC<LogProductionProps> = ({data, onLog, onClose, machine, nonConforming,
                                                 errors, scrapCodeList, ncOnly,
                                                 hideAvailable, typeName,
                                                 incDecLogic}) => {

  const [open, setOpen] = useState<boolean>(false)
  const [canSubmitValidIds, setCanSubmitValidIds] = useState<number[]>([])
  const [canChooseScrapCodeIds, setCanChooseScrapCodeIds] = useState<number[]>([])
  const [itemXs, setItemXs] = useState<number>(2)
  const [modalSize, setModalSize] = useState<any>('lg')
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {

    setFilteredData(data.filter((obj: any) => obj.balance > 0))
    data = data.filter((obj: any) => obj.balance > 0)

    if (data.length > 0) {
      setOpen(true)

      if (data.length === 1) {
        setItemXs(12)
        setModalSize('sm')
      } else if (data.length > 1 && data.length < 3) {
        setItemXs(6)
        setModalSize('md')
      } else if (data.length > 2 && data.length < 5) {
        setItemXs(3)
        setModalSize('lg')
      } else if (data.length > 4  && data.length < 7) {
        setItemXs(2)
        setModalSize('lg')
      } else if (data.length > 6) {
        setItemXs(1.5)
        setModalSize('xl')
      }

      if (errors && errors.length > 0)
        for (const err of errors) {
          setError(err.name, {type: 'server', message: err.message})
        }
      else {
        setCanSubmitValidIds([])
        reset({})
      }

    } else {
      setOpen(false)
      setIsSubmitted(false);
    }
  }, [data, errors, open])

  // setup form
  const methods = useForm({defaultValues: {}})
  const { handleSubmit, setError, reset, getValues, setValue, setFocus } = methods

  const updateValue = (name: string, increment: boolean, nc: boolean, maxValue: number) => {
    const values: any = getValues()
    const value: any = values[name]  // either conforming or nc

    const newValue: number = updateValueHelper(value, increment, maxValue)

    validateCanSubmit({target: {name: name, value: newValue}}, nc)
    setValue(name as never, newValue as never)

    // if conf += 1, non conf -= 1 and vice versa
    if (nonConforming) {
      let oppositeName: string
      let orderId: string
      if (name.includes('non')) {
        oppositeName = name.slice(4)
        orderId = name.split('_')[3]
      } else {
        oppositeName = 'non_' + name
        orderId = name.split('_')[2]
      }
      const oppositeValue: any = values[oppositeName]
      const newOppositeValue: number = updateValueHelper(oppositeValue, !increment, maxValue)
      const scrapCodeName: string = `scrap_code_${orderId}`

      if (incDecLogic) {
        validateCanSubmit({target: {name: oppositeName, value: newOppositeValue}}, !nc)
        setValue(oppositeName as never, newOppositeValue as never)

        // if non conf set to 0, clear the scrap code selection (inc dec logic)
        if ((name.includes('non') && !newValue) || (oppositeName.includes('non') && !newOppositeValue))
          setValue(scrapCodeName as never, '' as never)
      }

      // manual if non set to 0 clear the scrap code
      if (name.includes('non') && !newValue)
        setValue(scrapCodeName as never, '' as never)

      // if n/c was 0 or null before, and nc value is set, and scrap code value is not set, focus on the scrap code select
      const scrapCodeValue: string = values[scrapCodeName]

      // set timeout here to push this focus setting to the end of the event loop
      setTimeout(() => {
        if (((name.includes('non') && newValue) || (oppositeName.includes('non') && newOppositeValue)) &&
          (scrapCodeValue === '' || !scrapCodeValue)) {
          setFocus(`scrap_code_${orderId}` as never)
        }
      }, 0)
    }
  }

  const updateValueHelper = (value: any, increment: boolean, maxValue: number) => {
    let newValue: number

    // have to cast these values for setValue function
    if (value && value !== '')
      if (increment)
        newValue = parseInt(value) + 1
      else
        newValue = parseInt(value) - 1
    else
    if (increment)
      newValue = 1
    else
      newValue = 0

    if (newValue <= maxValue)
      return newValue
    else
      return value
  }

  // submit validator
  const validateCanSubmit = (e: any, nc: boolean) => {
    // console.log('validate can submit :', e, nc, canChooseScrapCodeIds)
    if (nc)
      validateNonConformingValueEntered(e)

    // checks if there is an entered value in every column
    const splitString: string[] = e.target.name.split('_')
    const id: number = parseFloat(splitString[splitString.length - 1])

    // if adding
    if (id && !canSubmitValidIds.includes(id))
      setCanSubmitValidIds([...canSubmitValidIds, id])
    else if (e.target.value === '')
      setCanSubmitValidIds(canSubmitValidIds.filter((_id: number) => _id !== id))

  }

  // validate non conforming entered
  const validateNonConformingValueEntered = (e: any) => {
    // enables the scrap drop down when non conforming is entered on the column and disables when removed
    const splitString: string[] = e.target.name.split('_')
    const value: string | number = e.target.value
    const id: number = parseFloat(splitString[splitString.length - 1])

    // if non conforming
    if (id && !canChooseScrapCodeIds.includes(id) && splitString.length === 4)
      setCanChooseScrapCodeIds([...canChooseScrapCodeIds, id])
    else if (value === '' || value === '0' || value === 0)  // if nc set to none, disable ss select
      setCanChooseScrapCodeIds(canChooseScrapCodeIds.filter((_id: number) => _id !== id))
  }

  // helpers
  const handleReset = () => {
    setIsSubmitted(false)
    setCanChooseScrapCodeIds([])
    setCanSubmitValidIds([])
    reset({})
  }
  const formatDescriptionText = (text: string) => {
    if (text.trim().length > DESC_LENGTH)
      text = text.substring(0, DESC_LENGTH) + '...'

    return text
  }
  const getMaxValue = (item: any) => {
    let max: number = 0

    if (item.loaded_count && item.mold_yield)
      max = item.loaded_count * item.mold_yield
    else if (item.available && item.available !== '-')
      max = item.available
    else if (item.balance)
      max = item.balance

    return max
  }
  const getModalName = () => {
    let name: string = ncOnly ? 'Log Production Non Conforming' : 'Log Production'

    if (typeName)
      name += ` - ${typeName}`

    return name
  }

  const onSubmitHandler = (data: any) => {
    setIsSubmitted(true)
    onLog(data)
  }

  return (
    <BaseModal
      title={getModalName()}
      open={open}
      closeHandler={onClose}
      actions={[
        {text: 'Reset', action: handleReset, left: true, outlined: true},
        {text: 'Submit', action: handleSubmit(onSubmitHandler), disabled: !(filteredData.length === canSubmitValidIds.length) || isSubmitted}

      ]}
      maxWidth={modalSize}
      closeButton
      dividers
    >
      <FormProvider {...methods}>
        <Grid container spacing={3}>
          <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Order:</Typography></Grid>
          <Grid item xs={10}>
            <Grid container justifyContent='space-around' spacing={2}>
              {filteredData.map((item: any, key: number) => (
                <Grid item xs={itemXs} key={key} sx={{textAlign: 'center'}}>
                  {item.number}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Item:</Typography></Grid>
          <Grid item xs={10}>
            <Grid container justifyContent='space-around' spacing={2}>
              {filteredData.map((item: any, key: number) => (
                <Grid item xs={itemXs} key={key} sx={{textAlign: 'center'}}>
                  {item.item}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Description:</Typography></Grid>
          <Grid item xs={10}>
            <Grid container justifyContent='space-around' spacing={2}>
              {filteredData.map((item: any, key: number) => (
                <Grid item xs={itemXs} key={key} sx={{textAlign: 'center'}}>
                  {formatDescriptionText(item.description)}
                </Grid>
              ))}
            </Grid>
          </Grid>
          {!ncOnly ?
            <>
              <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Conforming Parts:</Typography></Grid>
              <Grid item xs={10}>
                <Grid container justifyContent='space-around' spacing={1}>
                  {filteredData.map((item: any, key: number) => (
                    <Grid item xs={itemXs} container key={key} spacing={1} display='flex' justifyContent='center'>
                      <Grid item sx={{mt: '20px'}}>
                        <FormInputNumber name={`conforming_parts_${item.id}`} label='Parts' min={0} max={getMaxValue(item)}
                                         onChange={(e: any) => validateCanSubmit(e, false)} sx={{maxWidth: MAX_WIDTH}} />
                      </Grid>
                      <Grid item>
                        <Grid item xs={12} sx={{mb: 1}}>
                          <Button variant='contained' color='primary' onClick={() => updateValue(`conforming_parts_${item.id}`,  true, false, getMaxValue(item))}>
                            +
                          </Button>
                        </Grid>
                        <Grid item xs={12} sx={{mt: 1}}>
                          <Button variant='contained' color='primary' sx={{backgroundColor: '#fc2626'}} onClick={() => updateValue(`conforming_parts_${item.id}`,  false, false, getMaxValue(item))}>
                            -
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
            : <></>}
          {hideAvailable === undefined || !hideAvailable ?
            <>
              <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Available:</Typography></Grid>
              <Grid item xs={10}>
                <Grid container justifyContent='space-around' spacing={1}>
                  {filteredData.map((item: any, key: number) => (
                    <Grid item xs={itemXs} key={key} sx={{textAlign: 'center'}}>
                      {item.available}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
            :
            <></>
          }
          <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Balance:</Typography></Grid>
          <Grid item xs={10}>
            <Grid container justifyContent='space-around' spacing={1}>
              {filteredData.map((item: any, key: number) => (
                <Grid item xs={itemXs} key={key} sx={{textAlign: 'center'}}>
                  {item.balance}
                </Grid>
              ))}
            </Grid>
          </Grid>
          {nonConforming || (ncOnly !== undefined && ncOnly) ?
            <>
              <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Non Conforming Parts:</Typography></Grid>
              <Grid item xs={10}>
                <Grid container justifyContent='space-around' spacing={1}>
                  {filteredData.map((item: any, key: number) => (
                    <Grid item xs={itemXs} container key={key} spacing={1} display='flex' justifyContent='center'>
                      <Grid item sx={{mt: '20px'}}>
                        <FormInputNumber name={`non_conforming_parts_${item.id}`} label='Parts' min={0} max={getMaxValue(item)}
                                         onChange={(e: any) => validateCanSubmit(e, true)} sx={{maxWidth: MAX_WIDTH}} />
                      </Grid>
                      <Grid item>
                        <Grid item xs={12} sx={{mb: 1}}>
                          <Button variant='contained' onClick={() => updateValue(`non_conforming_parts_${item.id}`,  true, true, getMaxValue(item))}>
                            +
                          </Button>
                        </Grid>
                        <Grid item xs={12} sx={{mt: 1}}>
                          <Button variant='contained' sx={{backgroundColor: '#fc2626'}} color='primary' onClick={() => updateValue(`non_conforming_parts_${item.id}`,  false, true, getMaxValue(item))}>
                            -
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Scrap Code:</Typography></Grid>
              <Grid item xs={10}>
                <Grid container justifyContent='space-around' spacing={1}>
                  {filteredData.map((item: any, key: number) => (
                    <Grid item xs={itemXs} container key={key} justifyContent='center'>
                      <Grid item sx={{minWidth: MAX_WIDTH + 50}}>
                        <FormInputDropdown
                          sx={{maxWidth: MAX_WIDTH + 50}}
                          name={`scrap_code_${item.id}`}
                          label='Scrap Code'
                          options={scrapCodeList}
                          disabled={!canChooseScrapCodeIds.includes(item.id)}
                          blurOnChange
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
            : <></>}
          {machine ?
            <>
              <Grid item xs={2}><Typography align='right' sx={{fontWeight: 'bold'}}>Machine Shutdown:</Typography></Grid>
              <Grid item xs={10} container justifyContent='center'>
                <Grid item xs={12}><FormInputSwitch name='machine_shutdown' label='' /></Grid>
              </Grid>
            </>
            : <></>}
        </Grid>
      </FormProvider>
    </BaseModal>
  )
}

export default LogProduction